import { createContextFromValues } from 'wonderland-ui-commons/lib/Forms/formUtils';
import { ToastController, withWonderlandForm } from 'wonderland-ui-commons';
import FormDialog from 'app/shared/SchemaFormDialog';
import history from 'lib/history';
import PropTypes from 'prop-types';
import React from 'react';

const schema = props => {
    return [
        {
            available: true,
            required: true,
            label: 'Name',
            type: 'text',
            name: 'name'
        },
        {
            available: true,
            name: 'duplicate_metadata_only',
            type: 'checkbox',
            label: 'Only duplicate metadata (No files will be duplicated)'
        }
    ];
};

const redirectTo = path => history.push(path);

export const onSubmit = (props, { name, duplicate_metadata_only: onlyDuplicateMetadata }) => {
    const { assetId, duplicateAsset, destroyForm, hide, redirectDelay } = props;

    const duplicate = { name, onlyDuplicateMetadata };

    hide();
    destroyForm();
    duplicateAsset(assetId, duplicate).then(r => {
        const prefix = r.asset_type === 'collection' ? 'collection' : 'asset';
        ToastController.show(`Successfully duplicated asset '${r.name}' `);
        setTimeout(() => redirectTo(`/${prefix}/${r.id}/metadata`), redirectDelay);
    });
};

@withWonderlandForm({
    formName: 'duplicateAsset',
    showSubmitButton: false,
    schema,
    initialContext: props => createContextFromValues({ duplicate_metadata_only: props.checked, name: props.assetName }),
    config: {
        destroyOnUnmount: false,
        enableReinitialize: true
    },
    onSubmit
})
export default class DuplicateAssetModalComponent extends React.Component {
    static propTypes = {
        visible: PropTypes.bool,
        hide: PropTypes.func,
        assetName: PropTypes.string,
        assetId: PropTypes.string,
        duplicateAsset: PropTypes.func,
        checked: PropTypes.bool,
        validateForm: PropTypes.func.isRequired,
        WonderlandSchemaForm: PropTypes.func.isRequired,
        redirectDelay: PropTypes.number
    };

    render() {
        try {
            return this.doRender();
        } catch (e) {
            console.error('DuplicateAssetModalComponent::render', e);
        }
        return null;
    }

    doRender() {
        const { visible, hide, WonderlandSchemaForm } = this.props;
        return (
            <FormDialog
                visible={visible}
                hide={hide}
                onSubmit={this.validateForm}
                submitLabel="Duplicate"
                formLabel="Duplicate Asset"
            >
                <WonderlandSchemaForm />
            </FormDialog>
        );
    }

    validateForm = () => this.props.validateForm({ fireSubmitAutomatically: true });
}
