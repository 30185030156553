import { compose, withHandlers, withProps } from 'recompose';
import { get, map } from 'lodash';
import { withMediaInfo } from 'wonderland-ui-aspera';
import config from 'app/config';
import SecurityController from 'core/Security';

const { extractionRuleContentPolicyType, extractionType, full, mediaInfoWasmPath, validatedMimeTypes } = get(
    config,
    'preIngestValidation.mediaInfo'
);

const shouldValidateFileArray = files => {
    let shouldValidate = false;
    map(files, file => {
        // currently only want to validate single file from computer
        if (file && file.source_type !== 's3' && file.type !== 'inode/directory') {
            shouldValidate = true;
        }
    });
    return shouldValidate;
};

const isMimeTypeValidationCandidate = type => validatedMimeTypes.includes(type);

const isAssetValidationCandidate = async assetIds => {
    // TODO add role check for update on UI too
    const rules = await SecurityController.evaluateContentPolicy(
        extractionRuleContentPolicyType ?? 'validation',
        assetIds,
        {
            categorize: true,
            asset_format: 'single_file'
        }
    );
    return get(rules, [assetIds, 'extraction', 'validation_profile', 'extraction_type']) === extractionType;
};

export default function withPreIngestValidation(initialContext = {}) {
    return compose(
        withProps(initialContext),
        withMediaInfo({ mediaInfoWasmPath, full }),
        withHandlers({
            shouldValidateAsset:
                ({ mediaInfo }) =>
                async (inputFile, assetIds) => {
                    if (
                        shouldValidateFileArray(inputFile) &&
                        isMimeTypeValidationCandidate(inputFile[0].type) &&
                        (await isAssetValidationCandidate(assetIds))
                    ) {
                        return await mediaInfo(inputFile[0]);
                    } else {
                        return false;
                    }
                }
        })
    );
}
