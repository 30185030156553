import { SecurityAPI } from 'core/apis';
import { ToastController } from 'wonderland-ui-commons';
import addApplicationCategory from 'wonderland-ui-security/lib/lib/addApplicationCategory';
import config from 'app/config';
import SecurityActions from './SecurityActions';

function getUsers() {
    SecurityActions.getUsersRequested();
    return SecurityAPI.getUsers({ skip: 0, limit: 10 }).then(
        r => SecurityActions.getUsersCompleted(r.results),
        e => {
            SecurityActions.getUsersFailed(e);
            ToastController.showError(e);
        }
    );
}

async function getUsersPagination({
    skip = 0,
    limit = 25,
    skipCount = true,
    userFilter = null,
    groupFilter = null,
    roleFilter = null,
    sort = 'first_name',
    sortDir = '1'
} = {}) {
    SecurityActions.getUsersPaginationRequested();
    return await SecurityAPI.getUsers({
        skip,
        limit,
        skipCount,
        filter: userFilter,
        group: groupFilter ? groupFilter.map(x => x.value) : groupFilter,
        role: roleFilter ? roleFilter.map(x => x.value) : roleFilter,
        sort,
        sortDir
    }).then(
        res => {
            SecurityActions.getUsersPaginationCompleted({ ...res, userFilter, groupFilter, roleFilter });
        },
        e => {
            SecurityActions.getUsersPaginationFailed(e);
            ToastController.showError(e);
        }
    );
}

function getGroups(term) {
    SecurityActions.getGroupsRequested();
    return SecurityAPI.getGroups({
        skip: 0,
        limit: 100000,
        sort: 'description',
        filter: term
    }).then(
        r => SecurityActions.getGroupsCompleted(r.results),
        e => {
            SecurityActions.getGroupsFailed(e);
            ToastController.showError(e);
        }
    );
}

function getGroupsPagination({ skip = 0, limit = 25 } = {}) {
    SecurityActions.getGroupsPaginationRequested();
    return SecurityAPI.getGroups({ skip, limit, group: true }).then(
        res => {
            SecurityActions.getGroupsPaginationCompleted(res);
        },
        e => {
            SecurityActions.getGroupsPaginationFailed(e);
            ToastController.showError(e);
        }
    );
}

function createUser(data) {
    return SecurityAPI.createUser(data).catch(e => {
        ToastController.showError(e);
        throw e;
    });
}

function deleteUser(data) {
    return SecurityAPI.deleteUser(data.name)
        .catch(e => {
            ToastController.showError(e);
            throw e;
        })
        .then(getUsers);
}

async function deleteUsers(ids) {
    await SecurityAPI.deleteUsers(ids).catch(e => ToastController.showError(e));
}

function editUser(name, data) {
    return SecurityAPI.editUser(name, data).catch(e => {
        ToastController.showError(e);
        throw e;
    });
}

async function editUsers(data) {
    await SecurityAPI.editUsers(data).catch(e => ToastController.showError(e));
}

function createGroup(data) {
    return SecurityAPI.createGroup(addApplicationCategory(data, config.namespace, config.category.assets))
        .catch(e => {
            ToastController.showError(e);
            throw e;
        })
        .then(getGroups);
}

function editGroup(name, data) {
    return SecurityAPI.editGroup(name, data)
        .catch(e => {
            ToastController.showError(e);
            throw e;
        })
        .then(getGroups);
}

function deleteGroup(data) {
    return SecurityAPI.deleteGroup(data.name)
        .catch(e => {
            ToastController.showError(e);
            throw e;
        })
        .then(getGroups);
}

function getRoles() {
    SecurityActions.getRolesRequested();
    return SecurityAPI.getRoles().then(
        r => SecurityActions.getRolesCompleted(r),
        e => {
            SecurityActions.getRolesFailed(e);
            ToastController.showError(e);
        }
    );
}

function getRolesUnfiltered(sort = 'description') {
    SecurityActions.getRolesRequested();
    return SecurityAPI.getRolesUnfiltered(sort).then(
        r => SecurityActions.getRolesCompleted(r),
        e => {
            SecurityActions.getRolesFailed(e);
            ToastController.showError(e);
        }
    );
}

function getUser(name) {
    return SecurityAPI.getUser(name).catch(e => {
        ToastController.showError(e);
        throw e;
    });
}

function lookupUsers(term) {
    return SecurityAPI.getUsers({ skip: 0, limit: 500, filter: term }).then(resp =>
        SecurityActions.getUsersCompleted(resp.results)
    );
}

function lookupGroups(term) {
    return SecurityAPI.getGroups({ skip: 0, limit: 500, filter: term, namespace: config.namespace }).then(
        resp => resp.results
    );
}
function getAcl(id) {
    return SecurityAPI.getAcl(id);
}

function evaluateContentPolicy(type, ids, context) {
    return SecurityAPI.evaluateContentPolicy(type, ids, context);
}

export default {
    getUsers,
    getUsersPagination,
    getGroups,
    getGroupsPagination,
    createUser,
    deleteUser,
    deleteUsers,
    editUser,
    editUsers,
    createGroup,
    editGroup,
    deleteGroup,
    getRoles,
    getRolesUnfiltered,
    getUser,
    lookupUsers,
    lookupGroups,
    getAcl,
    evaluateContentPolicy
};
