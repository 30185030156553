import { get, sortBy } from 'lodash';
import { getBurnInSettings, getFilteredOptions } from 'core/Share/ShareUtils';
import { reducerRegistry } from 'lib/redux';
import { registerSliceReducer, sliceReducer, subscribe } from 'react-redux-boilerout';
import moment from 'moment';

@registerSliceReducer({ registry: reducerRegistry })
@sliceReducer('Share')
@subscribe({ namespace: 'Share' })
export default class ShareSliceReducer {
    initialState() {
        return {
            assetValidationErrors: [],
            config: {
                asset_ids: [],
                asset_grants: [],
                type: null
            },
            disableForensic: true,
            disableBurnInForm: true,
            download: true,
            forensic: false,
            formData: {
                expirationDate: moment().startOf('day').add(365, 'days').toDate(),
                name: '',
                remarks: '',
                startDate: moment().startOf('day').toDate()
            },
            isAssetValidationDone: false,
            nonShareableOptions: [],
            nonTrans: false,
            nonTransOptions: [],
            option: {},
            optionIndex: -1,
            options: [],
            originals: false,
            preview: false,
            tab: '',
            tabs: [],
            totalAssets: 0,
            totalTranscodableAssets: 0,
            burnIn: true,
            burnInText: ''
        };
    }

    onChangeBurnIn(slice, burnInText = '') {
        return { ...slice, burnInText };
    }

    onClear(slice) {
        const { optionIndex, options: sliceOptions } = slice;
        const options = sliceOptions.map(mediaType => ({
            ...mediaType,
            profiles: mediaType.profiles.map(option => ({
                ...option,
                checked: false
            }))
        }));
        const option = options[optionIndex];

        return {
            ...slice,
            nonTrans: false,
            option,
            options
        };
    }

    onFieldChange(slice, fieldName, fieldValue) {
        const { formData } = slice;
        const updatedFormData = { ...formData, [fieldName]: fieldValue };

        return { ...slice, formData: updatedFormData };
    }

    onInvalid(slice, nonShareableOptions) {
        return {
            ...slice,
            nonShareableOptions
        };
    }

    onReset() {
        return this.initialState();
    }

    onSetConfig(slice, config) {
        return {
            ...slice,
            config,
            totalAssets: get(config, 'asset_ids', []).length
        };
    }

    onSetTab(slice, tab) {
        const { options } = slice;
        const optionIndex = options.findIndex(({ name }) => name === tab);
        const option = options[optionIndex];

        return { ...slice, option, optionIndex, tab };
    }

    onStatsCompleted(slice, stats) {
        const { options, nonTransOptions } = getFilteredOptions(stats);
        const tabNames = options.map(({ name }) => name);
        const tabs = sortBy(tabNames);
        const tab = get(tabs, [0], '');
        const optionIndex = options.findIndex(({ name }) => name === tab);
        const option = options[optionIndex];

        return {
            ...slice,
            isAssetValidationDone: true,
            nonTransOptions,
            option,
            optionIndex,
            options,
            tab,
            tabs
        };
    }

    onToggleChecked(slice, profileIndex) {
        const { download: sliceDownload, forensic: sliceForensic, options: sliceOptions, tab } = slice;
        const optionIndex = sliceOptions.findIndex(({ name }) => name === tab);
        const checkedValue = !get(sliceOptions, [optionIndex, 'profiles', profileIndex, 'checked'], true);
        const options = sliceOptions.map((mediaType, index) => ({
            ...mediaType,
            profiles:
                index !== optionIndex
                    ? mediaType.profiles
                    : mediaType.profiles.map((option, idx) => ({
                          ...option,
                          checked: idx !== profileIndex ? option.checked : checkedValue
                      }))
        }));
        const { disableForensic, disableBurnInForm, download, forensic } = getBurnInSettings(
            sliceDownload,
            sliceForensic,
            options
        );
        const option = options[optionIndex];

        return { ...slice, disableBurnInForm, disableForensic, download, forensic, option, options };
    }

    onToggleDownload(slice) {
        const { download: sliceDownload, forensic: sliceForensic, options } = slice;
        const download = !sliceDownload;
        const { disableForensic, forensic } = getBurnInSettings(download, sliceForensic, options);

        return { ...slice, disableForensic, download, forensic };
    }

    onToggleForensic(slice) {
        const { forensic } = slice;

        return { ...slice, forensic: !forensic };
    }

    onToggleNonTrans(slice) {
        const { nonTrans } = slice;

        return { ...slice, nonTrans: !nonTrans };
    }

    onToggleOriginals(slice) {
        const { originals } = slice;

        return { ...slice, originals: !originals };
    }

    onTogglePreview(slice) {
        const { preview } = slice;

        return { ...slice, preview: !preview };
    }

    onValidateCompleted(slice, assetValidationErrors, config) {
        return {
            ...slice,
            assetValidationErrors,
            config
        };
    }

    onBurnInDisable(slice) {
        return { ...slice, burnIn: false };
    }

    onBurnInEnable(slice) {
        return { ...slice, burnIn: true };
    }
}
