import { actionDispatcher } from 'react-redux-boilerout';

@actionDispatcher({
    namespace: 'Security',
    actions: [
        'updateAcl',
        'clearSelectedItems',
        'deselectCurrentItems',
        'getUsersRequested',
        'getUsersCompleted',
        'getUsersFailed',
        'getUsersPaginationRequested',
        'getUsersPaginationCompleted',
        'getUsersPaginationFailed',
        'getGroupsRequested',
        'getGroupsCompleted',
        'getGroupsFailed',
        'getGroupsPaginationRequested',
        'getGroupsPaginationCompleted',
        'getGroupsPaginationFailed',
        'getRolesRequested',
        'getRolesCompleted',
        'getRolesFailed',
        'selectCurrentItems',
        'setGroupFilter',
        'submitBulkEditUserForm',
        'toggleItemSelection',
        'onToggleDeleteConfirmationModal',
        'userAction'
    ]
})
export default class SecurityActions {}
